import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import block from '../../img/Home/block.png';
import Fade from 'react-reveal/Fade';
import { useTranslation, Trans } from "react-i18next";

function Fast() {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [trades, setTrades] = useState(0);
    const [executionSpeed, setExecutionSpeed] = useState(0);

    useEffect(() => {
        if (isVisible) {
            const targetTrades = 86;
            const targetSpeed = 0.014;

            const incrementTrades = () => {
                if (trades < targetTrades) {
                    setTrades(prevTrades => prevTrades + 1);
                }
            };

            const incrementSpeed = () => {
                if (executionSpeed < targetSpeed) {
                    setExecutionSpeed(prevSpeed => prevSpeed + 0.0014);
                }
            };

            const tradesInterval = setInterval(incrementTrades, 10);
            const speedInterval = setInterval(incrementSpeed, 50);

            return () => {
                clearInterval(tradesInterval);
                clearInterval(speedInterval);
            };
        }
    }, [isVisible, trades, executionSpeed]);

    return (
        <VisibilitySensor onChange={setIsVisible}>
            <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade left>
                        <div className="xl:w-[802px] h-[147px] xl:h-[289px] rounded-[10px] mx-[20px]">
                            <img src={block} alt="" className="absolute object-cover w-full h-full rounded-[10px]" />
                            <div className="relative z-50 mt-[37px] xl:mt-[60px] ml-[10px] xl:ml-[35px]">
                                <h1 className="max-w-[127px] xl:max-w-max inter text-[16px] xl:text-[30px] font-extrabold text-[#212121]">
                                    <Trans>
                                        {t('fast_platform_heading')}
                                    </Trans>
                                </h1>
                                <div className="opacity-0 xl:opacity-100 buttontwo mt-[30px]">
                                    <a href="https://user.topforex.pro">
                                        <p className="text-[#212121] inter text-[14px] xl:text-[16px]">
                                            {t('fast_learn_more')}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <div className="flex flex-row xl:flex-col justify-between mx-[20px] xl:mx-0 gap-[10px] xl:gap-0 mt-[10px] xl:mt-0">
                        <Fade right delay={300}>
                            <div className="flex flex-col justify-center items-center w-1/2 xl:w-[383px] h-[124px] rounded-[10px] bg-[#F3F2F2] border border-solid border-gray-900">
                                <h1 className="inter text-[#212121] text-[30px] xl:text-[50px] font-light">{trades}</h1>
                                <p className="inter text-[#212121] text-[16px] font-light text-center">
                                    {t('fast_trades_label')}
                                </p>
                            </div>
                        </Fade>
                        <Fade right delay={500}>
                            <div className="flex flex-col justify-center items-center w-1/2 xl:w-[383px] h-[124px] rounded-[10px] bg-[#DCF598] border border-solid border-gray-900">
                                <h1 className="inter text-[#212121] text-[30px] xl:text-[50px] font-light">
                                    {executionSpeed.toFixed(3)}
                                </h1>
                                <p className="inter text-[#212121] text-[14px] xl:text-[16px] font-light text-center">
                                    {t('fast_execution_speed_label')}
                                </p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </VisibilitySensor>
    );
}

export default Fast;
