import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import arrow from "../img/arrow.svg";
import logo from "../img/logo.svg";
import logo_mob from "../img/logo_mob.svg";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t } = useTranslation();
    const location = useLocation();
    
    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const toggleProductsMenu = () => {
        setIsProductsOpen(!isProductsOpen);
    };

    const closeProductsMenu = () => {
        setIsProductsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsProductsOpen(false);
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'cz', label: 'CZ' },
        { value: 'pl', label: 'PL' },
        { value: 'ar', label: 'AR' },
        { value: 'de', label: 'DE' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="header-container">
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center">
                <Link to="/"><img src={logo} alt=""/></Link>
                <div className="flex cursor-pointer" onClick={toggleProductsMenu}>
                    <p className="text-white text-[16px] font-light open-sans">{t('header_products')}</p>
                    <img src={arrow} alt="" className={isProductsOpen ? "ml-[6px] transform rotate-90" : "ml-[6px]"} />
                    {isProductsOpen && (
                        <div className="absolute w-[347px] h-[136px] bg-[#212121] rounded-[15px] top-[65px]">
                            <div className="flex justify-between mx-[30px] my-[20px]">
                                <div className="flex flex-col gap-[15px]">
                                    <Link to="/indices"><p className={`text-[16px] font-light open-sans ${location.pathname === '/indices' ? 'text-[#DCF598]' : 'text-white'}`}>{t('indices')}</p></Link>
                                    <Link to="/forex"><p className={`text-[16px] font-light open-sans ${location.pathname === '/forex' ? 'text-[#DCF598]' : 'text-white'}`}>{t('forex')}</p></Link>
                                    <Link to="/commodities"><p className={`text-[16px] font-light open-sans ${location.pathname === '/commodities' ? 'text-[#DCF598]' : 'text-white'}`}>{t('commodities')}</p></Link>
                                </div>
                                <div className="flex flex-col gap-[15px]">
                                    <Link to="/cryptocurrency"><p className={`text-[16px] font-light open-sans ${location.pathname === '/cryptocurrency' ? 'text-[#DCF598]' : 'text-white'}`}>{t('cryptocurrencyheader')}</p></Link>
                                    <Link to="/shares"><p className={`text-[16px] font-light open-sans ${location.pathname === '/shares' ? 'text-[#DCF598]' : 'text-white'}`}>{t('shares')}</p></Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Link to="/costscharges" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/costscharges' ? 'text-[#DCF598]' : 'text-white'}`}>{t('costs_charges')}</p></Link>
                <Link to="/referfriend" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/referfriend' ? 'text-[#DCF598]' : 'text-white'}`}>{t('refer_friend')}</p></Link>
                {/* <Link to="/education" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/education' ? 'text-[#DCF598]' : 'text-white'}`}>{t('header.education')}</p></Link> */}
                <Link to="/accounts" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/accounts' ? 'text-[#DCF598]' : 'text-white'}`}>{t('header.accounts')}</p></Link>
                <Link to="/copytrading" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/copytrading' ? 'text-[#DCF598]' : 'text-white'}`}>{t('copy_trading')}</p></Link>
                <Link to="/aboutus" onClick={closeProductsMenu}><p className={`text-[16px] font-light open-sans ${location.pathname === '/aboutus' ? 'text-[#DCF598]' : 'text-white'}`}>{t('about_us')}</p></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                />
                <a href="https://user.topforex.pro">
                    <div className="button cursor-pointer">
                        <p className="inter text-white text-[16px]">{t('start_now')}</p>
                    </div>
                </a>
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo_mob} alt="" /></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
