import React from "react";
import { useTranslation } from "react-i18next";
import girl from '../../img/Home/girl.png';
import other from '../../img/Home/other.png';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function Main() {
    
    const { t } = useTranslation();

    return (
        <div>
            <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[50px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold">
                            {t('main_empowering_investors')}
                            <span className="text-[#02995A]">{t('main_trading_strategies')}</span>
                        </h1>
                        <p className="hidden xl:block mt-[35px] max-w-[525px] inter text-[16px] text-[#212121] font-light">
                            {t('main_discover_alpha_flow')}
                        </p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.pro">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('main_start_now')}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px]">
                        <Zoom bottom><img src={other} alt="" className="absolute xl:top-[250px] z-50" /></Zoom>
                        <Fade duration={1000}><img src={girl} alt="" className="xl:ml-[130px] w-[297px] h-[320px] xl:w-[522px] xl:h-[562px] mx-auto" /></Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <div className="buttontwo mt-[50px]">
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('main_start_now')}</p>
                            </div>
                        </div>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[90px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">
                            {t('main_discover_alpha_flow')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
