import React from "react";

import { useTranslation } from "react-i18next";

import girl from '../../img/CopyTrading/image.png'

import Fade from 'react-reveal/Fade'

function Main () {
    
    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[50px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("copytrading.title")}</span></h1>
                        <p className="hidden xl:block mt-[30px] max-w-[525px] inter text-[16px] text-[#212121] font-medium">{t("copytrading.text1")}</p>
                        <p className="hidden xl:block mt-[15px] max-w-[525px] inter text-[16px] text-[#212121] font-light">{t("copytrading.text2")}</p>
                        <p className="hidden xl:block mt-[15px] max-w-[525px] inter text-[16px] text-[#212121] font-light">{t("copytrading.text3")}</p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.pro">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t("start_now")}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px]">
                        <Fade duration={1000}><img src={girl} alt="" className="xl:ml-[130px] mx-auto xl:w-[494px] xl:h-[420px]"/></Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <div className="buttontwo mt-[50px]">
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t("start_now")}</p>
                            </div>
                        </div>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[90px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t("copytrading.text1")}</p>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[15px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t("copytrading.text2")}</p>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[15px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t("copytrading.text3")}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Main;